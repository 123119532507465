import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

import { Img } from './common/Img.style';
import Link from './Link';
import { getWidthBySize } from './layouts/MainContainer';
import InfoText from './InfoText';

import hero2lg from '../assets/img/hero-2-lg.png';
import melg from '../assets/img/me-lg.png';
import memd from '../assets/img/me-md.png';
import mesm from '../assets/img/me-sm.png';
import covidIcon from '../assets/img/covid-icon.svg';
import video from '../assets/img/video.svg';
import keshin from '../assets/img/kenshin_logo.png';

const Container = styled.div`
  width: ${({ size }) =>
    (size === 'lg' && '1280px') || (size === 'md' && '768px') || (size === 'sm' && '100%')};
  margin: 0 auto 20px auto;
`;

const BannerImg = styled(Img)`
  width: ${({ size }) =>
    (size === 'lg' && '996px') || (size === 'md' && '768px') || (size === 'sm' && '100%')};
`;

const HealtCheckCaption = styled.div`
  position: absolute;
  top: ${({ size }) => (size === 'lg' && 10) || (size === 'md' && 30) || 7}px;
  right: ${({ size }) => (size === 'lg' && 7) || (size === 'md' && 7) || 4}px;
`;

const Iframe = styled.iframe`
  border: inherit;
`;

const Icon = styled.img`
  width: ${({ size }) => (size === 'sm' && 25) || 50}px;
  margin-left: ${({ size }) => (size !== 'sm' && 20) || 0}px;
  object-fit: contain;
`;

const ButtonAreaFlex = styled(Flex)`
  width: ${({ size }) =>
    (size === 'lg' && '324px') || (size === 'md' && '250px') || (size === 'sm' && '166px')};
`;
const CalendarButton = styled(Flex)`
  height: 80px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
  border: solid 4px #f7b13c;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const ReExaminationButton = styled(Flex)`
  height: 80px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
  border: solid 4px #f7b13c;
  background-color: #fffbce;
  box-sizing: border-box;
`;
const MdCheckAnnounceButton = styled(Flex)`
  height: 80px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
  border: solid 4px #f0862e;
  background-color: #f0862e;
  box-sizing: border-box;
`;
const HealthCheckButton = styled(Flex)`
  height: 80px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
  border: solid 4px #146724;
  background-color: #146724;
  box-sizing: border-box;
`;
const ReserveButton = styled(Flex)`
  height: 80px;
  width: 80%;
  margin: 10px auto;
  border-radius: 6px;
  border: solid 4px #4abaa7;
  background-color: #4ab8ba;
  box-sizing: border-box;
`;
const ReserveButtonCl = styled(Flex)`
  height: 80px;
  width: 80%;
  margin: 10px auto;
  border-radius: 6px;
  border: solid 4px #0f79d1;
  background-color: #0f79d1;
  box-sizing: border-box;
`;

const Text = styled.div`
  font-size: 18px;
  color: #333333;
  font-weight: 600;
`;
const GrayText = styled.div`
  font-size: 18px;
  color: #666666;
`;
const WhiteText = styled.div`
  font-size: 16px;
  color: #ffffff;
`;

const CaptionText = styled.span`
  font-size: 16px;
  color: #146724;
`;

const KenshinLogo = styled.img`
  margin-right: 10px;
`;

const EnglishPageLinkText = styled.span`
  font-size: ${({ size }) => (size === 'sm' && 12) || 14}px;
  color: #333333;
`;

const HealtCheckCaptionBox = ({ size }) => (
  <div
    css={`
      width: ${(size === 'sm' && 235) || 250}px;
      margin: 0 auto;
    `}
  >
    <Flex
      column
      css={`
        padding-top: 20px;
        padding-bottom: 20px;
        opacity: 0.8;
        background-color: #ffffff;
        border-radius: 3px;
      `}
    >
      <FlexItem>
        <Flex center>
          <Flex
            column
            css={`
              margin-bottom: 10px;
            `}
          >
            <CaptionText>健康診断・人間ドックを</CaptionText>
            <CaptionText>ご希望の方はこちら</CaptionText>
          </Flex>
        </Flex>
      </FlexItem>

      <FlexItem>
        <Flex center>
          <KenshinLogo src={keshin} />
          <CaptionText
            css={`
              font-weight: bold;
            `}
          >
            健診・ドック専用ページ
          </CaptionText>
        </Flex>
      </FlexItem>
    </Flex>
  </div>
);

const HomeTopArea = ({ size }) => (
  <Container size={size}>
    <Flex center>
      <FlexItem>
        <Flex justifyEnd>
          <Link to="/english">
            <Flex
              css={`
                margin: 10px 25px;
              `}
            >
              <Flex
                center
                css={`
                  margin-right: 8px;
                `}
              >
                <FontAwesomeIcon icon={faGlobe} style={{ fontSize: 18 }} color="gray" />
              </Flex>
              <Flex center>
                <EnglishPageLinkText size={size}>English</EnglishPageLinkText>
              </Flex>
            </Flex>
          </Link>
        </Flex>
        <BannerImg src={hero2lg} size={size} />
        {size !== 'lg' && (
          <Flex center>
            <FlexItem
              css={`
                position: relative;
              `}
            >
              <Link to="https://www.hirahata.net/" external>
                <Img src={(size === 'md' && memd) || mesm} />
                <HealtCheckCaption size={size}>
                  <HealtCheckCaptionBox size={size} />
                </HealtCheckCaption>
              </Link>
            </FlexItem>
          </Flex>
        )}
        <Flex
          center
          css={
            (size !== 'lg' &&
              `
            width: ${(size === 'md' && 608) || getWidthBySize(size)}px;
            margin: 0 auto;
          `) ||
            ''
          }
        >
          <Flex
            column
            justifyCenter
            css={`
              margin: 0 auto; // TODO
            `}
          >
            <Iframe src="https://wait.hirahata-clinic.or.jp/" width={160} height={180} />
          </Flex>
          <Flex
            column
            justifyCenter
            css={`
              margin: 0 auto; // TODO
            `}
          >
            <ButtonAreaFlex size={size} column>
              <Link to="/covid19">
                <MdCheckAnnounceButton center column={size === 'sm'} size={size}>
                  <WhiteText>新型コロナ後遺症外来</WhiteText>
                  <Icon src={covidIcon} size={size} />
                </MdCheckAnnounceButton>
              </Link>
              <Link to="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya" external>
                <ReExaminationButton center column={size === 'sm'} size={size}>
                  <GrayText>オンライン診療</GrayText>
                  <Icon src={video} size={size} />
                </ReExaminationButton>
              </Link>
            </ButtonAreaFlex>
          </Flex>
          <ButtonAreaFlex size={size} column>
            {size !== 'sm' && (
              <Link to="/schedule">
                <CalendarButton center>
                  <GrayText>担当医・診療科カレンダー</GrayText>
                </CalendarButton>
              </Link>
            )}
            {size !== 'sm' && (
              <Link to="https://www.hirahata.net/" external>
                <HealthCheckButton center>
                  <WhiteText>健康診断・人間ドック</WhiteText>
                </HealthCheckButton>
              </Link>
            )}
          </ButtonAreaFlex>
        </Flex>
      </FlexItem>
      {/*  
      {size === 'lg' && (
        <FlexItem
          css={`
            position: relative;
          `}
        >
          <Link to="https://www.hirahata.net/" external>
            <Img src={melg} />
            <HealtCheckCaption size={size}>
              <HealtCheckCaptionBox size={size} />
            </HealtCheckCaption>
          </Link>
        </FlexItem>
      )}
  */}
    </Flex>
    <p
      css={`
        margin: 2rem 0;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        color: #993333;
      `}
    >
      {/* 院長休診等はここに直書きして表示させる */}
      ＊4月1日(月)〜4月11日(金)の平日の日中は待合の混雑が予想されます。
      <br />
      ＊7月6日(日)13:00～15:00の院長外来は休診となります。
      <br />
      ご迷惑をお掛けいたします。
      <InfoText /> {/* 受付入力のおしらせ */}
    </p>
    <div
      css={`
        padding-bottom: 2rem;
      `}
    >
      <Link to="https://quiq-0334003288.peernist.co.jp/WT" external>
        <ReserveButtonCl center>
          <WhiteText>外来の予約はこちら</WhiteText>
        </ReserveButtonCl>
      </Link>
      <Link to="https://reserva.be/hirahatacovidtest" external>
        <ReserveButton center>
          <WhiteText>** 物理療法の予約はこちら **</WhiteText>
        </ReserveButton>
      </Link>
    </div>
  </Container>
);

export default HomeTopArea;
